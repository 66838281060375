import { Theme } from '@material-ui/core/styles';
import useDetectTenantType from '../components/Custom/useDetectTenantType';
// eslint-disable-next-line import/no-cycle
import defaultTheme from './default';
import squirrelTheme from './squirrel';
import systemConceptsTheme from './system-concepts';

export interface IAppTheme extends Theme {
  mandatory?: {
    color?: string;
  }
  disabledFormElements?: {
    backgroundColor?: string;
    color?: string;
  }
  assigneeSummary?: {
    one: {
      backgroundColor: string;
      color: string;
    }
    two: {
      backgroundColor: string;
      color: string;
    }
  }
  siteTitle?: {
    width: string;
  }
  libraryFileIcon?: {
    color: string;
  }
  automatedTasks?: {
    defaultColor?: string;
    colorLight?: string;
    colorDark?: string;
  }
  offlineMessage?: {
    backgroundColor?: string;
    color?: string;
  }
  fixedFormButtons?: {
    backgroundColor?: string;
  }
}

const useGetTheme = () => {
  const favicon = document.getElementById('favicon');
  const [tenantType] = useDetectTenantType();

  if (tenantType === 'squirrel') {
    if (favicon) {
      favicon.setAttribute('href', '/squirrel.ico');
    }
    document.getElementsByTagName('title')[0].innerText = 'VetPartners Squirrel';
    return squirrelTheme;
  }

  if (tenantType === 'systemconcepts') {
    if (favicon) {
      favicon.setAttribute('href', '/systemconcepts.ico');
    }
    document.getElementsByTagName('title')[0].innerText = 'System Concepts';
    return systemConceptsTheme;
  }

  return defaultTheme;
};

export default useGetTheme;
