import { createMuiTheme, lighten } from '@material-ui/core/styles';

/*
edb540 (ORANGE)
ff5c39 (RED)
6e6962 (DARK GREY)
968f8a (MID GREY)
f2f2f1 (LIGHT GREY)
------
960200
DB2763
12EAEA
BCE7FD
C492B1
3581B8
002E2C
494368
FC60A8
7A28CB
494368
1B998B
7C90A0
EDE580
A4AF69
4C2719
*/

const siteCSSVars = {
  offlineMessage: {
    backgroundColor: '#504a43',
    color: '#fff',
  },
  mandatory: {
    color: '#c80000',
  },
  disabledFormElements: {
    backgroundColor: '#f6f6f6',
    color: '#333',
  },
  assigneeSummary: {
    one: {
      backgroundColor: '#7C90A0',
      color: '#ffffff',
    },
    two: {
      backgroundColor: '#BCE7FD',
      color: '#111111',
    },
  },
  siteTitle: {
    width: '170px',
  },
  libraryFileIcon: {
    color: '#A4AF69',
  },
  automatedTasks: {
    defaultColor: '#1c2430',
    colorLight: lighten('#1c2430', 0.9),
    colorDark: lighten('#1c2430', 0.8),
  },
  fixedFormButtons: {
    backgroundColor: '#f2f2f1',
  },
};

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#111111',
    },
    background: {
      default: '#f2f2f1',
      paper: '#fff',
    },
    primary: {
      main: '#ff5c39',
    },
    secondary: {
      main: '#edb540',
    },
    error: {
      main: '#960200',
    },
    warning: {
      main: '#FFDDA1',
      dark: '#6e6962',
    },
    success: {
      main: '#628B48',
    },
    type: 'light',
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: [
      // '"Gotham"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
  },
});

theme.overrides = {
  MuiAccordion: {
    root: {
      width: '100%',
      margin: '12px',
      '&$expanded': {
        margin: '12px',
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      flexDirection: 'column',
    },
  },
  MuiAccordionSummary: {
    root: {
      backgroundColor: '#f6f6f6',
      '&$expanded': {
        backgroundColor: '#fff',
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: 'rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;',
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: '#A4AF69',
    },
  },
  MuiButton: {
    root: {
      '&.themed-submit-button': {
        color: '#fff',
        fontWeight: '700',
      },
    },
    contained: {
      backgroundColor: '#f2f2f1',
      color: '#6e6962',
      '&:hover': {
        color: '#f2f2f1',
        backgroundColor: '#6e6962',
      },
    },
    containedSecondary: {
      color: '#ffffff',
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      '&$disabled': {
        '& .MuiSvgIcon-root': {
          color: siteCSSVars.disabledFormElements.color,
        },
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      margin: '24px 0',
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: '#6e6962',
      '& h1': {
        backgroundColor: '#f2f2f1',
      },
      '& .MuiListItemText-root': {
        color: '#ffffff',
      },
      '& .MuiListItemIcon-root': {
        color: '#ffffff',
      },
      '& .MuiCollapse-wrapper': {
        backgroundColor: '#968f8a',
      },
      '& .nav--footer': {
        backgroundColor: '#f2f2f1',
        '& .MuiListItemText-primary': {
          color: '#111111',
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
    asterisk: {
      color: siteCSSVars.mandatory.color,
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    label: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiIconButton: {
    root: {
      '&.action-button': {
        color: '#4C2719',
        '&:hover': {
          color: '#edb540',
        },
      },
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        backgroundColor: siteCSSVars.disabledFormElements.backgroundColor,
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiTab: {
    labelIcon: {
      minHeight: '48px',
    },
    wrapper: {
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      '& > :first-of-type': {
        marginBottom: '0',
        marginRight: theme.spacing(1),
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9rem',
      backgroundColor: '#6e6962',
      color: '#fff',
    },
    arrow: {
      color: '#6e6962',
    },
  },
};

const squirrelTheme = { ...theme, ...siteCSSVars };

export default squirrelTheme;
