import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { GridSize } from '@material-ui/core';
import { useGetMyTenant } from './apollo-hooks';
import ActivityIndicator from './components/ActivityIndicator';
import ErrorDisplay from './components/ErrorDisplay';
import UserProfileContext from './UserProfileContext';
import { IAssignee } from './formly/components/Assignees';

type UiData = {
  showSiteControl?: boolean;
  quickLinks?: Array<{
    label: string;
    type: string;
    args: Array<any>;
    excludeSiteIds?: Array<number>;
  }>;
  automatedTaskDefaults?: {
    assigneesBySiteId?: {
      [siteId: number]: Array<IAssignee>;
    }
    taskDueInDays?: number;
  };
  dashboards: Array<{
    permissions: Array<string>;
    widgets: Array<{
      type: string;
      props?: { [name: string]: any };
      sm?: GridSize;
      md?: GridSize;
      lg?: GridSize;
      xl?: GridSize;
    }>;
  }>;
};

interface IContext {
  uiData: UiData | null;
}

const UiDataProviderContext = createContext<IContext | undefined>(undefined);

export function UiDataProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const userProfile = useContext(UserProfileContext);

  const { loading, data, error } = useGetMyTenant();
  const [uiData, setUiData] = useState<UiData | null>();

  useEffect(() => {
    if (data?.myTenant) {
      const { myTenant } = data;

      if (myTenant.uiData) {
        setUiData(JSON.parse(myTenant.uiData));
      } else {
        setUiData(null);
      }
    }
  }, [data]);

  if (!userProfile) {
    return <ErrorDisplay error="UiDataProvider must be used inside an AuthenticatedRoute" />;
  }

  if (loading || uiData === undefined) {
    return <ActivityIndicator />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <UiDataProviderContext.Provider value={{ uiData }}>
      {children}
    </UiDataProviderContext.Provider>
  );
}

export function useUiDataProvider(): IContext {
  const context = useContext(UiDataProviderContext);

  if (!context) {
    throw new Error('useUiDataProvider must be used inside a UiDataProvider.');
  }

  return context;
}
