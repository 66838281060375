import { schemeAccent, schemeCategory10, schemeDark2, schemePaired, schemePastel1, schemePastel2, schemeSet1, schemeSet2, schemeSet3, schemeTableau10 } from 'd3-scale-chromatic';
import React from 'react';

export default function Colors() {
  const colorSchemes = {
    schemeCategory10,
    schemeAccent,
    schemeDark2,
    schemePaired,
    schemePastel1,
    schemePastel2,
    schemeSet1,
    schemeSet2,
    schemeSet3,
    schemeTableau10,
  };

  return (
    <>
      {Object.entries(colorSchemes)
        .map(([name, colorScheme]) => colorScheme.map((color, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} style={{ background: color, padding: '20px' }}>
            {`${name}[${index}]: ${color}`}
          </div>
        )))}
    </>
  );
}
