import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import SiteContext from '../Sites/SiteContext';
import ModuleLinkButton from '../Modules/ModuleLinkButton';

interface IProps {
  links: Array<{
    label: string;
    type: string;
    args: Array<any>;
    excludeSiteIds?: Array<number>;
  }>;
}

export default function QuickLinks(props: IProps) {
  const { links } = props;

  const siteContext = useContext(SiteContext);

  const linksToShow = siteContext
    ? links.filter((link) => !link.excludeSiteIds
      || !link.excludeSiteIds.includes(siteContext.siteId))
    : null;

  if (linksToShow === null || linksToShow.length === 0) {
    return null;
  }

  return (
    <Box mt={3}>
      <Typography variant="h2" gutterBottom>Quick links</Typography>
      <Grid container spacing={3} alignItems="stretch">
        {linksToShow.map((link, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={i} item sm={12} md={4}>
            <ModuleLinkButton to={`/form-templates/${link.args[0]}/forms/add`}>
              {link.label}
            </ModuleLinkButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
